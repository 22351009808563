import { Component, Vue } from 'vue-property-decorator';

import { CtaInlineBlockViewModel } from './CtaInlineModule.types';
import { getComponentProps } from '@/utils/vueUtils';

import CtaItem from '@/components/CtaItem/CtaItem.vue';
import { CtaButtonStyle } from '@/common/interfaces/CtaLinkItem';

const CtaInlineModuleProps = Vue.extend({
  props: getComponentProps<CtaInlineBlockViewModel>({
    Header: 'Talk to an experience specialist',
    SecondHeader: '1.800.397.3348',
    Button: {
      Title: 'Call now',
      Link: '1.800.397.3348',
      Action: 'Phone',
      ButtonStyle: CtaButtonStyle.Primary,
    },
  }),
});

@Component({
  components: { CtaItem },
})
export default class CtaInlineModule extends CtaInlineModuleProps {}
